import { ReactNode, Suspense, useCallback, useState } from 'react'
import { Router, Routes, useRouter } from 'blitz'
import {
  AddImageMajor,
  AppsMajor,
  CapitalMajor,
  CirclePlusMinor,
  ColorsMajor,
  ProductsMajor,
  TextMajor,
  VariantMajor
} from '@shopify/polaris-icons'
import { AppProvider, Frame, Navigation, Page, TopBar } from '@shopify/polaris'
import _ from 'lodash'
import enTranslations from '@shopify/polaris/locales/en.json'
import '@shopify/polaris/build/esm/styles.css'
import { useCurrentUser } from '@/hooks/useCurrentUser'
import { useEnvironment } from '@/hooks/useEnvironment'
import HeadMain from '@/core/components/HeadMain'
import { useAdminLayout } from '@/hooks/useAdminLayout'
import { NeverInProduction } from '@/core/components/NeverInProduction'
import { LoadingSpinner } from '@/core/components/LoadingSpinner'

const debug = require('debug')('lf:navigation')

type LayoutProps = {
  title?: string
  children: ReactNode
}

const TemplatesSection = () => {
  const { allTemplates } = useAdminLayout()
  // debug('TemplatesSection', allTemplates)
  const items = _.map(allTemplates, (template, id) => ({
    url: `/templates/${id}`,
    label: template.display_name,
    icon: AppsMajor
  }))
  return (
    <Navigation.Section
      title="Product Templates"
      items={items}
      action={{
        accessibilityLabel: 'Add a template',
        icon: CirclePlusMinor,
        onClick: () => Router.push(Routes.NewTemplatePage().pathname)
      }}
    />
  )
}

const ProductsSection = () => {
  const { allProducts } = useAdminLayout()

  // debug('ProductsSection', allProducts)
  const items = Object.values(allProducts).map((product) => {
    return {
      url: `/products/${product.id}`,
      label: product.title,
      icon: ProductsMajor
    }
  })
  return (
    <Navigation.Section
      title="Products"
      items={items}
      action={{
        accessibilityLabel: 'Add a product',
        icon: CirclePlusMinor,
        onClick: () => Router.push(Routes.NewProductPage().pathname)
      }}
      separator
    />
  )
}

const AssetsSection = () => {
  return (
    <Navigation.Section
      title="Global Assets"
      items={[
        {
          url: Routes.FontsPage().pathname,
          label: 'Fonts',
          icon: TextMajor
        },
        {
          url: Routes.PatchesPage().pathname,
          label: 'Patches',
          icon: VariantMajor
        },
        {
          url: Routes.ThreadSwatchesPage().pathname,
          label: 'Thread Swatches',
          icon: ColorsMajor
        },
        {
          url: Routes.FabricSwatchesPage().pathname,
          label: 'Fabric Swatches',
          icon: AddImageMajor
        },
        {
          url: Routes.SymbolsPage().pathname,
          label: 'Symbols',
          icon: CapitalMajor
        }
      ]}
      separator
    />
  )
}

const DeveloperTools = () => {
  const { theme_id, shop_name } = useEnvironment()

  return (
    <NeverInProduction>
      <Navigation.Section
        // title="Miscellaneous"
        items={[
          {
            url: '/',
            disabled: true,
            label: 'SmallPlanet',
            subNavigationItems: [
              {
                label: 'Zoom',
                url: 'https://smallplanet.zoom.us/j/86337860475?pwd=QmR2QjE0cmNEMkpoQzFKbzJxRHBDZz09'
              },
              {
                url: 'https://smallplanetdigital.atlassian.net/jira/software/c/projects/LFPC/boards/28',
                label: 'Jira'
              },
              {
                url: 'https://smallplanetdigital.atlassian.net/wiki/spaces/LFPC/overview?homepageId=1434452085',
                label: 'Confluence'
              },
              {
                url: 'https://www.figma.com/file/XgDGgZ1HlMTjL2eIsMIDQO/LF-Admin?node-id=84%3A4365',
                label: 'Figma-Admin'
              },
              {
                url: 'https://www.figma.com/file/uBCgPzxZglDI6oHfRvNAxQ/LF-Visual-Design-V1.0?node-id=55%3A1869',
                label: 'Figma-Customer'
              }
            ]
          },
          {
            url: '/',
            disabled: true,
            label: 'Shopify',
            subNavigationItems: [
              {
                url: `https://${shop_name}/admin/themes/${theme_id}`,
                label: 'Shopify Theme'
              },
              {
                url: `https://linguafranca.nyc/collections/custom?preview_theme_id=${theme_id}`,
                label: 'Shopify Preview'
              },
              {
                url: 'https://github.com/SmallPlanetClients/lf_Shopify_Theme',
                label: 'Github Theme'
              },
              {
                url: `https://github.com/SmallPlanetClients/lf_Customizer_Website`,
                label: 'Github App'
              }
            ]
          },
          {
            url: '/',
            disabled: true,
            label: 'Third Party Services',
            subNavigationItems: [
              {
                url: 'https://vercel.com/smallplanet/lf-customizer-website',
                label: 'Vercel'
              },
              {
                url: 'https://console.firebase.google.com/u/0/project/lf-test-d47ff/database/lf-test-d47ff-default-rtdb/data',
                label: 'Firebase'
              },
              {
                url: '/api/backup-database/now',
                label: 'Dump Database'
              },
              {
                url: 'https://app.planetscale.com/smallplanet/lf-develop',
                label: 'Planetscale'
              },
              {
                url: 'https://logtail.com/team/50678/tail',
                label: 'Logtail'
              },
              {
                url: 'https://logflare.app/sources/16286',
                label: 'Logflare'
              }
            ]
          }
        ]}
        separator
      />
    </NeverInProduction>
  )
}

const RichNav = () => {
  const { pathname } = useRouter()
  return (
    <Navigation location={pathname}>
      <Suspense fallback={<LoadingSpinner />}>
        <TemplatesSection />
        <ProductsSection />
      </Suspense>
      <AssetsSection />
      <DeveloperTools />
    </Navigation>
  )
}

const UserMenu = () => {
  const [userMenuActive, setUserMenuActive] = useState(false)
  const currentUser = useCurrentUser()
  const name = currentUser?.name || ''
  const initials = name
    .split(' ')
    .map((n) => n.charAt(0))
    .join('')
  const userMenuActions = [
    {
      items: [{ content: 'Logout', url: Routes.LogoutPage().pathname }]
    }
  ]

  const toggleUserMenuActive = useCallback(() => setUserMenuActive((userMenuActive) => !userMenuActive), [])

  return (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={name}
      detail={'Lingua Franca'}
      initials={initials}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  )
}

export const AdminLayout = ({ title, children }: LayoutProps) => {
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false)

  const toggleMobileNavigationActive = useCallback(
    () => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive),
    []
  )

  const TopBarMarkup = () => (
    <Suspense fallback={<></>}>
      <TopBar showNavigationToggle userMenu={<UserMenu />} onNavigationToggle={toggleMobileNavigationActive} />
    </Suspense>
  )

  const theme = {
    logo: {
      width: 200,
      topBarSource:
        'https://cdn.shopify.com/s/files/1/1280/1673/files/LINGUA_FRANCA_LOGO_cc8ee587-5f60-4a05-87b5-d62cd5e0633e_1040x.jpg?v=1633705728',
      url: Routes.HomePage().pathname,
      accessibilityLabel: 'Lingua Franca'
    }
  }

  return (
    <AppProvider theme={theme} i18n={enTranslations}>
      <HeadMain title={title} />

      <Frame
        topBar={<TopBarMarkup />}
        navigation={<RichNav />}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}>
        <Page title={title}>{children}</Page>
      </Frame>
    </AppProvider>
  )
}
