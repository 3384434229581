import { Card } from '@shopify/polaris'
import _ from 'lodash'
import { useAudits } from '../hooks/useAudits'
import { ProductAlertCard } from './productAlertCard'
import { PatchAlertCard } from './patchAlertCard'
import { FabricSwatchAlertCard } from './fabricSwatchAlertCard'

const debug = require('debug')('lf:audit')

export default function CatalogAudit() {
  const { alertsByPatchId, alertsByProductId, missingFabricSwatchColors } = useAudits()

  // debug('CatalogAudit', { alertsByPatchId, alertsByProductId, missingFabricSwatchColors })

  const productAlertCards = _.map(alertsByProductId, (alerts, id) => {
    return <ProductAlertCard key={id} alerts={alerts} />
  })

  const patchAlertCards = _.map(alertsByPatchId, (alerts, id) => {
    return <PatchAlertCard key={id} alerts={alerts} />
  })

  const fabricSwatchAlertCards = _.map(missingFabricSwatchColors, (color, id) => {
    return <FabricSwatchAlertCard key={color} color={color} />
  })

  return (
    <>
      <Card>{productAlertCards}</Card>
      <Card>{patchAlertCards}</Card>
      <Card>{fabricSwatchAlertCards}</Card>
    </>
  )
}
