import { useMutation, useQuery } from 'blitz'
import GetAllTemplates from '../../templates/queries/GetAllTemplates'
import SetAdminProduct from '../../products/mutations/SetAdminProduct'
import DeleteProduct from '../../products/mutations/DeleteProduct'
import { useProducts } from '../../products/hooks/useProducts'

/**
 * this is a hack because I can't figure out how to make the Admin tool side panel
 * add/remove products as they're created/deleted.
 *
 * I was really hopify something like this would do it
 *
 *   await invalidateQuery(GetAllTemplates, {})
 *   await invalidateQuery(GetAdminProducts, {})
 *   await invoke(GetAdminProducts, {})
 *   await invalidateQuery(GetProductById, { product_id: product.id })
 *
 * but it doesn't seem to refresh the side pane.  So instead I'm going for this awkward thing
 */

export function useAdminLayout() {
  const { allProducts, refetchProducts } = useProducts()
  const [allTemplates, templateCtls] = useQuery(GetAllTemplates, {})
  const [setProduct] = useMutation(SetAdminProduct, { onSettled: refetchProducts })
  const [deleteProduct] = useMutation(DeleteProduct, { onSettled: refetchProducts })

  function refetchTemplates() {
    templateCtls.refetch()
  }

  return {
    allTemplates,
    allProducts,
    setProduct,
    deleteProduct,
    refetchTemplates,
    refetchProducts
  }
}
