import { useQuery } from 'blitz'
import GetAdminProducts from '../queries/GetAllAdminProducts'

export const useProducts = () => {
  const [allProducts, productCtls] = useQuery(GetAdminProducts, {})

  function refetchProducts() {
    return productCtls.refetch()
  }

  return {
    allProducts,
    productCtls,
    refetchProducts
  }
}
